import React, { useState } from 'react';
import './FormularioComponent.css';
import { Form, Modal } from "react-bootstrap";
import { Button } from "react-bootstrap";
import{ init, sendForm } from '@emailjs/browser';
import apiKey from './emailkey';
init(apiKey.USER_ID);

const FormularioComponent = () => {

    const nombre_ref = React.createRef<HTMLInputElement>();
    const apellido_ref = React.createRef<HTMLInputElement>();
    const correo_ref = React.createRef<HTMLInputElement>();
    const telefono_ref = React.createRef<HTMLInputElement>();
    const consulta_ref = React.createRef<HTMLTextAreaElement>();
    const [show, setShow] = useState(false);
    const [modalContent, setModalContent] = useState('');

    const handleClose = () => {
        setShow(false)
    }
    const handleOnSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        // const full_name: string = nombre_ref.current?.value + ' ' + apellido_ref.current?.value;
        // const email_address: string =  correo_ref.current?.value+'';
        
        sendForm(
            apiKey.SERVICE_ID, 
            apiKey.TEMPLATE_ID, 
            e.currentTarget,
            apiKey.USER_ID,
            )
            .then((result) => {
                setModalContent(`La consulta ha sido enviada correctamente. Status: ${result.text}`)
                setShow(true)
            },(error) => {
                setModalContent(`Ha ocurrido un error al enviar la consulta, por favor intente nuevamente, Error: ${error.text}`)
                setShow(true)
            });
            console.log(e.currentTarget)
        if(nombre_ref.current != null && apellido_ref.current != null && correo_ref.current != null && telefono_ref.current != null && consulta_ref.current != null){
            nombre_ref.current.value = '';
            apellido_ref.current.value = '';
            correo_ref.current.value = '';
            telefono_ref.current.value = '';
            consulta_ref.current.value = '';
        }
        e.preventDefault();
    };

    return(
        <>
        <div id="contacto" className="formulario-container">
            <Modal centered show={show}>
                <Modal.Header>
                    <Modal.Title>Estado del formulario:</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    { modalContent }
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary"  onClick={handleClose}>Cerrar</Button>
                </Modal.Footer>
            </Modal>
            <div className='formulario-img'></div>
            <div className='formulario'>
                <h2 className="formulario-title" >Dejanos tu consulta, y a la brevedad nos comunicamos con vos!</h2>
                <Form onSubmit={handleOnSubmit}>
                    <Form.Group className="mb-3" controlId="formBasicName">
                        <Form.Label>Nombre:</Form.Label>
                        <Form.Control ref={nombre_ref} required name="name" type="text" placeholder="Ingresar nombre" />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicLastName">
                        <Form.Label>Apellido:</Form.Label>
                        <Form.Control ref={apellido_ref} required name="last_name" type="text" placeholder="Ingresar apellido" />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Correo electronico:</Form.Label>
                        <Form.Control ref={correo_ref} required name="email" type="email" placeholder="Ingresar correo" />
                        <Form.Text className="text-white">
                        Nunca compartiremos tu correo con nadie.
                        </Form.Text>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicPhone">
                        <Form.Label>Telefono:</Form.Label>
                        <Form.Control ref={telefono_ref} name="phone" type="number" placeholder="Ingresar telefono" />
                    </Form.Group>
                    <label>Consulta:</label><br></br>
                    <textarea ref={consulta_ref} name="user_question" placeholder="Ingrese su consulta" className='input-consulta'></textarea><br/>
                    <Button variant="primary" type="submit">
                        Enviar
                    </Button>
                </Form>
            </div>
        </div>
        </>
    )
};

export default FormularioComponent;