import './HomePage.css';
// import React, { Suspense } from 'react';
import HeadComponent from '../HeadComponent/HeadComponent';
// import LazyComponentWrapper from '../LazyComponentWrapper/LazyComponentWrapper';

import CarouselComponent from '../CarouselComponent/CarouselComponent';
import Description1Component from '../Description1Component/Description1Component'
import ComfortDescriptionComponent from '../ComfortDescriptionComponent/ComfortDescriptionComponent';
import GalleryComponent from '../GalleryComponent/GalleryComponent';
import VirtualTourInvitationComponent from '../VirtualTourInvitationComponent/VirtualTourInvitationComponent';
import VirtualTourComponent from '../VirtualTourComponent/VirtualTourComponent';
import ServicesInvitationComponent from '../ServicesInvitationComponent/ServicesInvitationComponent';
import ServiciosComponent from '../ServiciosComponent/ServiciosComponent';
import FormularioComponent from '../FormularioComponent/FormularioComponent';
import FootComponent from '../FootComponent/FootComponent';


//lazy load
// const CarouselComponent = React.lazy(() => import('../CarouselComponent/CarouselComponent'))
// const Description1Component = React.lazy(() => import( '../Description1Component/Description1Component'));
// const ComfortDescriptionComponent = React.lazy(() => import( '../ComfortDescriptionComponent/ComfortDescriptionComponent'));
// const GridImagesComponent = React.lazy(() => import( '../GridImagesComponent/GridImagesComponent'));
// const VirtualTourInvitationComponent = React.lazy(() => import( '../VirtualTourInvitationComponent/VirtualTourInvitationComponent'));
// const VirtualTourComponent = React.lazy(() => import( '../VirtualTourComponent/VirtualTourComponent'));
// const ServicesInvitationComponent = React.lazy(() => import( '../ServicesInvitationComponent/ServicesInvitationComponent'));
// const ServiciosComponent = React.lazy(() => import( '../ServiciosComponent/ServiciosComponent'));
// const FormularioComponent = React.lazy(() => import( '../FormularioComponent/FormularioComponent'));
// const FootComponent = React.lazy(() => import( '../FootComponent/FootComponent'));

const HomePage = () => {

  return(
    <>
      <div className="home-component-container">
            <section>
              <HeadComponent/>
              <CarouselComponent/>
              <Description1Component/>
              <div className='comedor-img-container'></div>
              <ComfortDescriptionComponent/>
              <GalleryComponent/>
              <div className='front-building-img-container'></div>
              <VirtualTourInvitationComponent/>
              <VirtualTourComponent/>
              <ServicesInvitationComponent/>
              <ServiciosComponent/>
              <FormularioComponent/>
              <FootComponent/>
            </section>
        <div className='signature-container'>
          <a className="signature-logo" href='https://www.linkedin.com/in/ignacio-juan-heck/' target="_blank"></a>
          <p className='signature'>Developed with ☕ by Ignacio Juan Heck. </p>
        </div>
      </div>
    </>
  )
};

export default HomePage;