import './ServicesInvitationComponent.css'

const ServicesInvitationComponent = () => {

    return(
    <>
        <div id="servicios" className="services-invitation-component background">
          <div className="services-invitation-component-elements">
            <h2 className='services-invitation-title'>Servicios brindados</h2>
            <p className='services-invitation-parrafo'>No solo el acompañamiento médico y en salud es uno de los pilares de Buonavita. 
            <br/>Generar comodidad durante toda la estadía es la misión que hace a la Residencia.</p>
          </div>
        </div>
    </>
    )

}

export default ServicesInvitationComponent;