// import image1 from './../../assets/Fotos/0 - Exterior - frente/0 - 1-min.jpg';
import image2 from './../../assets/Fotos/1 - Pasillos/1 - 1-min.jpg';
import image3 from './../../assets/Fotos/1 - Pasillos/1 - 2-min.jpg';
import image4 from './../../assets/Fotos/1 - Pasillos/1 - 3-min.jpg';
import image5 from './../../assets/Fotos/1 - Pasillos/1 - 4-min.jpg';
import image6 from './../../assets/Fotos/2 - Habitacion/2 - 1-min.jpg';
import image7 from './../../assets/Fotos/2 - Habitacion/2 - 2-min.jpg';
import image8 from './../../assets/Fotos/2 - Habitacion/2 - 3-min.jpg';
import image9 from './../../assets/Fotos/3 - Comedor/3 - 1-min.jpg';
import image10 from './../../assets/Fotos/3 - Comedor/3 - 2-min.jpg';
import image11 from './../../assets/Fotos/4 - Buffet/4 - 1.jpeg';
import image12 from './../../assets/Fotos/4 - Buffet/4 - 2.jpeg';
import image13 from './../../assets/Fotos/4 - Buffet/4 - 3-min.jpg';
import image14 from './../../assets/Fotos/4 - Buffet/4 - 4-min.jpg';
import image15 from './../../assets/Fotos/4 - Buffet/4 - 5-min.jpg';
import image16 from './../../assets/Fotos/4 - Buffet/4 - 6-min.jpg';
import image17 from './../../assets/Fotos/4 - Buffet/4 - 7-min.jpg';
import image18 from './../../assets/Fotos/5 - Estar/5 - 1-min.jpg';
import image19 from './../../assets/Fotos/6 - Gimnasio/6 - 1-min.jpg';
import image20 from './../../assets/Fotos/6 - Gimnasio/6 - 2-min.jpg';
import image21 from './../../assets/Fotos/6 - Gimnasio/6 - 3-min.jpg';
import image22 from './../../assets/Fotos/7 - Exterior - fondo/7 - 1-min.jpg';
import image23 from './../../assets/Fotos/7 - Exterior - fondo/7 - 2-min.jpg';
import image24 from './../../assets/Fotos/7 - Exterior - fondo/7 - 3-min.jpg';
import image25 from './../../assets/Fotos/7 - Exterior - fondo/7 - 4-min.jpg';
import image26 from './../../assets/Fotos/7 - Exterior - fondo/7 - 5-min.jpg';


const photos = [
    // {
    //     'id' : 1,
    //     'path' : image1
    // },
    {
        'id' : 2,
        'path' : image2
    },
    {
        'id' : 3,
        'path' : image3
    },
    {
        'id' : 4,
        'path' : image4
    },
    {
        'id' : 5,
        'path' : image5
    },
    {
        'id' : 6,
        'path' : image6
    },
    {
        'id' : 7,
        'path' : image7
    },
    {
        'id' : 8,
        'path' : image8
    },
    {
        'id' : 9,
        'path' : image9
    },
    {
        'id' : 10,
        'path' : image10
    },
    {
        'id' : 11,
        'path' : image11
    },
    {
        'id' : 12,
        'path' : image12
    },
    {
        'id' : 13,
        'path' : image13
    },
    {
        'id' : 14,
        'path' : image14
    },
    {
        'id' : 15,
        'path' : image15
    },
    {
        'id' : 16,
        'path' : image16
    },
    {
        'id' : 17,
        'path' : image17
    },
    {
        'id' : 18,
        'path' : image18
    },
    {
        'id' : 19,
        'path' : image19
    },
    {
        'id' : 20,
        'path' : image20
    },
    {
        'id' : 21,
        'path' : image21
    },
    {
        'id' : 22,
        'path' : image22
    },
    {
        'id' : 23,
        'path' : image23
    },
    {
        'id' : 24,
        'path' : image24
    },
    {
        'id' : 25,
        'path' : image25
    },
    {
        'id' : 26,
        'path' : image26
    }
    
]

export default photos;