import React from 'react';
import { useScrollPosition } from '@n8tb1t/use-scroll-position'

const useNavbarScroll = () => {
    const layer1Ref = React.createRef<HTMLDivElement>();
    const layer2Ref = React.createRef<HTMLDivElement>();
    const layer3Ref = React.createRef<HTMLDivElement>();
    const [animateOnScroll, setAnimateOnScroll] = React.useState(false);
  useScrollPosition(({ prevPos, currPos }) => {
    const isShow = currPos.y < prevPos.y
    if (isShow !== animateOnScroll) setAnimateOnScroll(isShow)
  }, [animateOnScroll]);

  React.useEffect(() => {
    if(!animateOnScroll){
      layer1Ref.current!.style.top = "0%";
      layer1Ref.current!.style.transform = "scale(1.05)";
      layer2Ref.current!.style.top = "0%";
      layer2Ref.current!.style.transform = "scale(1.1)";
      layer3Ref.current!.style.top = "0%";
      layer3Ref.current!.style.transform = "scale(1.15)";
    } else {
      layer1Ref.current!.style.top = "2%";
      layer1Ref.current!.style.transform = "scale(1)";
      layer2Ref.current!.style.top = "2%";
      layer2Ref.current!.style.transform = "scale(1)";
      layer3Ref.current!.style.top = "2%";
      layer3Ref.current!.style.transform = "scale(1)";
    }
  }, [animateOnScroll]);
  return {layer1Ref,layer2Ref,layer3Ref}
}

export default useNavbarScroll;