import './ServiciosComponent.css';
import HexComponent from './HexComponent/HexComponent';
import { useState } from 'react' 


const ServiciosComponent = () => {
    const [serviceDescription, setServiceDescription] = useState<string[]>(['Clickeá las imagenes para conocer más!']);
    
    const handleHeartClick = () => {
        setServiceDescription([
            'Talleres de sabores, musicoterapia, huerta, teatro, pintura y manualidades.',
            'Estimulación cognitiva.' ,
            'Estimulación motora.',
            'Festejos de fechas importantes y actividades que refuerzan la autoestima.'
        ])
    }

    const handleBedClick = () => {
        setServiceDescription([
            'Alojamiento en habitación doble o single sin acompañantes.',
	        'Servicio de mucama para la habitación.',
    	    'Baños amplios adaptados.'
        ])
    }

    const handleRestaurantClick = () => {
        setServiceDescription([ 
            "Gastronomía gourmet.",
            "Pensión completa que incluye desayuno, almuerzo, merienda y cena.",
            "Las cuatro comidas serán las que indique o prescriba la Dirección Médica y los nutricionistas de residencia."])
    }

    const handleChessClick = () => {
        setServiceDescription([
            "Cine.",
            "Series y películas programadas.",
            "Talleres de lectura.",
            "Actividades lúdicas diarias.",
            "Gimnasio adaptado." 
        ])
    }

    const handleWifiClick = () => {
        setServiceDescription(['Internet Wi-Fi y equipamiento de tecnologia'])
    }

    const handleTowelClick = () => {
        setServiceDescription([
            'Lavandería incluida para ropa personal y de cama.',
            'Servicio de wifi.',
            'Servicio de televisión prepaga.',
            'Amplio espacio para actividades exteriores.',
            'Equipamiento exterior completo con parrilla.'
        ])
    }


    return(
        <>
        <div className='servicios-container'>
            <div className="hex-container">
                <div className="hex-fr">
                    <HexComponent onClick={handleHeartClick}><div className='hex-heart-bg hex-bg'><div className="hex-heart"></div></div></HexComponent>
                </div>
                <div className="hex-sr">
                    <HexComponent onClick={handleBedClick}><div className='hex-bed-bg hex-bg'><div className="hex-bed"></div></div></HexComponent>
                    <HexComponent onClick={handleRestaurantClick}><div className='hex-restaurant-bg hex-bg'><div className="hex-restaurant"></div></div></HexComponent>
                </div>
                <div className="hex-tr">
                    <HexComponent onClick={handleChessClick}><div className='hex-chess-bg hex-bg'><div className="hex-chess"></div></div></HexComponent>
                    <HexComponent onClick={handleWifiClick}><div className='hex-wifi-bg hex-bg'><div className="hex-wifi"></div></div></HexComponent>
                    <HexComponent onClick={handleTowelClick}><div className='hex-towel-bg hex-bg'><div className="hex-towel"></div></div></HexComponent>
                </div>
            </div>
            <div className="hex-description-container">
                <ul className='descripion-list'>
                    {serviceDescription && serviceDescription.map((description: string, index: number) => <li className='description-item' key={index}>{description}</li>)}
                </ul>
            </div>
        </div>
        </>
    )
};


export default ServiciosComponent;