import './App.css';
import Navbar from './components/Navbar/Navbar';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import HomePage from './components/HomePage/HomePage';
import NotFoundPage from './components/NotFoundPage/NotFoundPage';
import TagManager from 'react-gtm-module'
import ReactPixel from 'react-facebook-pixel';
import { useEffect } from 'react'

const tagManagerArgs = {
    gtmId: 'GTM-TKVZCRM'
}

TagManager.initialize(tagManagerArgs)


function App() {

  const advancedMatching = { 
    em: 'email@email.com',         //Values will be hashed automatically by the pixel using SHA-256
    fn: 'first_name',    
    ln: 'last_name',
    ct: 'city',
    db: 'birthdate',
    country: 'country',
    ge: 'gender',
    ph: 'phone',
    st: 'state or province',
    zp: 'zip or postal code', 
  } // optional, more info: https://developers.facebook.com/docs/facebook-pixel/pixel-with-ads/conversion-tracking#advanced_match
  const options = {
    autoConfig: true, // set pixel's autoConfig
    debug: false, // enable logs
  }

  useEffect(() => {
    ReactPixel.init('8hc6sa0ufdj944su9b9mv02q487ta5', advancedMatching, options)
    if (true) {
      ReactPixel.pageView()
    } else {
      ReactPixel.revokeConsent()
    }
  }, [])

  return (
    <>
    <Navbar></Navbar>
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<HomePage />}></Route>
          <Route path="/*" element={<NotFoundPage />}></Route>
          <Route path="/inicio" element={<HomePage />}></Route>
        </Routes>
      </div>
    </Router>
    </>
  );
}

export default App;