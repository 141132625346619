import './GalleryComponent.css'
import { Carousel, CarouselItem } from 'react-bootstrap';
// import * as photos from '../../assets/Fotos/local_photos';
import photos from '../../assets/Fotos/local_photos';



const GalleryComponent = () => {
  const carousel_item_list = photos.map((photo) => {
    return <CarouselItem key={photo.id}><img className="gallery-slide vertical-center" src={photo.path} alt="Gallery slide" /></CarouselItem>
  });
  
    return(
    <>
        <div id="galeria" className="home-component-4 background">
          <div className="home-component-4-elements">
            <Carousel pause={false} keyboard fade interval={3000}>
              {carousel_item_list}
            </Carousel>
          </div>
        </div>
    </>
    )
}

export default GalleryComponent;