import './ComfortDescriptionComponent.css';


const ComfortDescriptionComponent = () => {

    return(
    <>
        <div id="comodidades" className="purple-description background">
            <div className="pruple-description-elements">
                <h2 className='purple-description-title'>Confort y calidad</h2>
                <p className='purple-description-parrafo'>En buonavita ofrecemos comodidad, confort y calidad en cada espacio, actividad y servicio brindado. 
                Te invitamos a conocer los espacios que disponemos</p>
            </div>
        </div>
    </>
    )
} 

export default ComfortDescriptionComponent;