import React from 'react';
import logo2 from '../../assets/Logo-Color-AdaptacionSecundaria_w.png';
import './Navbar.css'
import { Nav } from 'react-bootstrap';
import { Navbar } from 'react-bootstrap';
import { Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.css';
import { useScrollPosition } from '@n8tb1t/use-scroll-position'

const NavigationBar = () => {
  const navbarRef = React.createRef<HTMLDivElement>();
  const whatsappButtonRef = React.createRef<HTMLAnchorElement>();
  const instagramButtonRef = React.createRef<HTMLAnchorElement>();
  const scrollUpButtonRef = React.createRef<HTMLAnchorElement>();
  const [hideOnTop, setHideOnTop] = React.useState(false);
  const [expanded, setExpanded] = React.useState(false);
  const [hideOnScroll, setHideOnScroll] = React.useState(false);

  useScrollPosition(({ prevPos, currPos }) => {
    const isShow = currPos.y < prevPos.y
    const isOnTop = currPos.y < -300
    if (isShow !== hideOnScroll) setHideOnScroll(isShow)
    if (isOnTop !== hideOnTop) setHideOnTop(isOnTop)
  }, [hideOnScroll]);

  React.useEffect(() => {
    navbarRef.current!.style.position = "fixed"
    navbarRef.current!.style.width = "100%"
    if(!hideOnScroll){
      navbarRef.current!.style.top = "0px";
    } else {
      navbarRef.current!.style.top = "-80px";
    }
  }, [hideOnScroll, navbarRef]);

  React.useEffect(() => {
    if (hideOnTop){
      scrollUpButtonRef.current!.style.transform = "scale(1)";
      whatsappButtonRef.current!.style.transform = "scale(1)";
      instagramButtonRef.current!.style.transform = "scale(1)";
    } else {
      scrollUpButtonRef.current!.style.transform = "scale(0)";
      whatsappButtonRef.current!.style.transform = "scale(0)";
      instagramButtonRef.current!.style.transform = "scale(0)";
    }
  }, [hideOnTop, scrollUpButtonRef, whatsappButtonRef, instagramButtonRef]);

  return( 
  <>
      <Navbar ref={navbarRef} className="navbar-class top-navbar" variant="dark" expand="lg" expanded={expanded}>
        <Container className="w-auto">
          <Navbar.Brand className="nav-bar-text-logo" href="/inicio">
            <img className="d-inline-block navbar-img" src={logo2} width="211" height="45" alt="Logo"></img>{' '}
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={() => setExpanded(expanded ? false : true)}/>
        </Container>
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-3">
              <Nav.Link className="text-white px-3" href="/#" onClick={() => setExpanded(false)}>Inicio</Nav.Link>
              <Nav.Link className="text-white px-3" href="/#nosotros" onClick={() => setExpanded(false)}>Nosotros</Nav.Link>
              <Nav.Link className="text-white px-3" href="/#comodidades" onClick={() => setExpanded(false)}>Comodidades</Nav.Link>
              <Nav.Link className="text-white px-3" href="/#galeria" onClick={() => setExpanded(false)}>Galeria</Nav.Link>
              <Nav.Link className="text-white px-3" href="/#virtual-tour-invitation-component" onClick={() => setExpanded(false)}>VR Tour</Nav.Link>
              <Nav.Link className="text-white px-3" href="/#servicios" onClick={() => setExpanded(false)}>Servicios</Nav.Link>
              <Nav.Link className="text-white px-3" href="/#contacto" onClick={() => setExpanded(false)}>Contacto</Nav.Link>
            </Nav>
          </Navbar.Collapse>
      </Navbar>
    

    <a ref={instagramButtonRef} className='instagram' target="_blank" rel="noreferrer" href='https://www.instagram.com/buonavitaresidencia/?utm_medium=copy_link'> </a>
    <a ref={whatsappButtonRef} className='whatsapp' target="_blank" rel="noreferrer" href='https://api.whatsapp.com/send?text=Buen%20d%C3%ADa%20quisiera%20consultar%20sobre%20&phone=542216049887'> </a>
    <a ref={scrollUpButtonRef} className='nav-link back-to-top' href="#">^</a>
  </>
  )
};

export default NavigationBar;