import { Pannellum } from "pannellum-react";
import React, { useRef, useState, useReducer } from 'react';
//360
import image2 from '../../assets/360/2.jpg';
import image2_1 from '../../assets/360/2-1.jpg';
import image3 from '../../assets/360/3.jpg';
import image4 from '../../assets/360/4.jpg';
import image4_1 from '../../assets/360/4-1.jpg';
import image5 from '../../assets/360/5.jpg';
import image6 from '../../assets/360/6.jpg';
import image7 from '../../assets/360/7.jpg';
import image8 from '../../assets/360/8.jpg';
import image9 from '../../assets/360/9.jpg';
import image9_1 from '../../assets/360/9-1.jpg';
import image9_2 from '../../assets/360/9-2.jpg';
import image10 from '../../assets/360/10.jpg';
import image11 from '../../assets/360/11.jpg';
import image12 from '../../assets/360/12.jpg';
import image13 from '../../assets/360/13.jpg';
import image14 from '../../assets/360/14.jpg';


import './VirtualTourComponent.css'

const cases = {
  image2: 'IMAGE_2',
  image2_1: 'IMAGE_2_1',
  image3: 'IMAGE_3',
  image4: 'IMAGE_4',
  image4_1: 'IMAGE_4_1',
  image5: 'IMAGE_5',
  image6: 'IMAGE_6',
  image7: 'IMAGE_7',
  image8: 'IMAGE_8',
  image9: 'IMAGE_9',
  image9_1: 'IMAGE_9_1',
  image9_2: 'IMAGE_9_2',
  image10: 'IMAGE_10',
  image11: 'IMAGE_11',
  image12: 'IMAGE_12',
  image13: 'IMAGE_13',
  image14: 'IMAGE_14'
}

const hotspotReducer = (state: any, action: any) => {
  switch (action.type) {
    case cases.image2:
      return 0;
    case cases.image3: 
      return 1;
    case cases.image4: 
      return 2;
    case cases.image4_1:
      return 3;
    case cases.image5:
      return 4;
    case cases.image6:
      return 5;
    case cases.image7:
      return 6;
    case cases.image8:
      return 7;
    case cases.image9:
      return 8;
    case cases.image9_1:
      return 9;
    case cases.image9_2:
      return 10;
    case cases.image10:
      return 11;
    case cases.image11:
      return 12;
    case cases.image12:
      return 13;
    case cases.image13:
      return 14;
    case cases.image14:
      return 15;
    default:
      return state;
  }
}

const VirtualTourComponent = () => {

  const [state, dispatch] = useReducer(hotspotReducer, 0)
  const pannellumRef = useRef(React.createRef<HTMLDivElement>());

  const hotspotsByImage = [{
    img: image2,
    hotspots: [
      <Pannellum.Hotspot
          type="custom"
          pitch={-17.74}
          yaw={-86.87}
          handleClick={() => dispatch({type: cases.image3})}
        />
      ,
      <Pannellum.Hotspot
          type="custom"
          pitch={-20.09}
          yaw={81.96}
          handleClick={() => dispatch({type: cases.image7})}
        />
    ]
  },
  {
    img: image3,
    hotspots: [
      <Pannellum.Hotspot
          type="custom"
          pitch={-18.09}
          yaw={97.96}
          handleClick={() => dispatch({type: cases.image2})}
        />
        ,
        <Pannellum.Hotspot
          type="custom"
          pitch={-28.61}
          yaw={-0.92}
          handleClick={() => dispatch({type: cases.image4})}
        />
    ]
  },
  {
    img: image4,
    hotspots: [
      <Pannellum.Hotspot
          type="custom"
          pitch={-18.23}
          yaw={149.74}
          handleClick={() => dispatch({type: cases.image2})}
        />
        ,
        <Pannellum.Hotspot
          type="custom"
          pitch={-38.27}
          yaw={179.42}
          handleClick={() => dispatch({type: cases.image3})}
        />
        ,
        <Pannellum.Hotspot
          type="custom"
          pitch={-30.61}
          yaw={-24.54}
          handleClick={() => dispatch({type: cases.image4_1})}
        />
        ,
        <Pannellum.Hotspot
          type="custom"
          pitch={-16.82}
          yaw={0.195}
          handleClick={() => dispatch({type: cases.image5})}
        />
    ]
  },
  {
    img: image4_1,
    hotspots: [
      <Pannellum.Hotspot
          type="custom"
          pitch={-25.88}
          yaw={-152.56}
          handleClick={() => dispatch({type: cases.image4})}
        />
    ]
  },
  {
    img: image5,
    hotspots: [
      <Pannellum.Hotspot
          type="custom"
          pitch={-27.44}
          yaw={179.52}
          handleClick={() => dispatch({type: cases.image4})}
        />
        ,
        <Pannellum.Hotspot
          type="custom"
          pitch={-21.27}
          yaw={0.64}
          handleClick={() => dispatch({type: cases.image6})}
        />
    ]
  },
  {
    img: image6,
    hotspots: [
      <Pannellum.Hotspot
          type="custom"
          pitch={-27.44}
          yaw={179.52}
          handleClick={() => dispatch({type: cases.image5})}
        />
        ,
        <Pannellum.Hotspot
          type="custom"
          pitch={-20.77}
          yaw={-15.38}
          handleClick={() => dispatch({type: cases.image10})}
        />
        ,
        <Pannellum.Hotspot
          type="custom"
          pitch={-20.77}
          yaw={21.76}
          handleClick={() => dispatch({type: cases.image11})}
        />
    ]
  },
  {
    img: image7,
    hotspots: [
      <Pannellum.Hotspot
          type="custom"
          pitch={-14.51}
          yaw={0.44}
          handleClick={() => dispatch({type: cases.image8})}
        />
        ,
        <Pannellum.Hotspot
          type="custom"
          pitch={-28.09}
          yaw={-95.22}
          handleClick={() => dispatch({type: cases.image2})}
        />
    ]
  },
  {
    img: image8,
    hotspots: [
      <Pannellum.Hotspot
          type="custom"
          pitch={-15.85}
          yaw={179.48}
          handleClick={() => dispatch({type: cases.image7})}
        />
        ,
        <Pannellum.Hotspot
          type="custom"
          pitch={-39.28}
          yaw={-0.31}
          handleClick={() => dispatch({type: cases.image9})}
        />
    ]
  },
  {
    img: image9,
    hotspots: [
      <Pannellum.Hotspot
          type="custom"
          pitch={-23.99}
          yaw={-0.20}
          handleClick={() => dispatch({type: cases.image12})}
        />
        ,
        <Pannellum.Hotspot
          type="custom"
          pitch={-32.32}
          yaw={178.23}
          handleClick={() => dispatch({type: cases.image8})}
        />
        ,
        <Pannellum.Hotspot
          type="custom"
          pitch={-25.13}
          yaw={87.76}
          handleClick={() => dispatch({type: cases.image9_1})}
        />
    ]
  },
  {
    img: image9_1,
    hotspots: [
      <Pannellum.Hotspot
          type="custom"
          pitch={-31.16}
          yaw={-160.69}
          handleClick={() => dispatch({type: cases.image9})}
        />
        ,
        <Pannellum.Hotspot
          type="custom"
          pitch={-38.06}
          yaw={118.78}
          handleClick={() => dispatch({type: cases.image9_2})}
        />
    ]
  },
  {
    img: image9_2,
    hotspots: [
      <Pannellum.Hotspot
          type="custom"
          pitch={-23.01}
          yaw={167.38}
          handleClick={() => dispatch({type: cases.image9_1})}
        />
    ]
  },
  {
    img: image10,
    hotspots: [
      <Pannellum.Hotspot
      type="custom"
      pitch={-15.30}
      yaw={103.58}
      handleClick={() => dispatch({type: cases.image6})}
    />
    ,
    <Pannellum.Hotspot
      type="custom"
      pitch={-15.21}
      yaw={88.41}
      handleClick={() => dispatch({type: cases.image11})}
    />
    ]
  },
  {
    img: image11,
    hotspots: [
      <Pannellum.Hotspot
          type="custom"
          pitch={-17.95}
          yaw={-132.99}
          handleClick={() => dispatch({type: cases.image6})}
        />
      ,
      <Pannellum.Hotspot
        type="custom"
        pitch={-15.03}
        yaw={-94.95}
        handleClick={() => dispatch({type: cases.image10})}
      />
      ,
      <Pannellum.Hotspot
        type="custom"
        pitch={-18.84}
        yaw={87.17}
        handleClick={() => dispatch({type: cases.image12})}
      />
    ]
  },
  {
    img: image12,
    hotspots: [
      <Pannellum.Hotspot
          type="custom"
          pitch={-20.61}
          yaw={-94.70}
          handleClick={() => dispatch({type: cases.image11})}
        />
        ,
        <Pannellum.Hotspot
          type="custom"
          pitch={-20.17}
          yaw={-11.78}
          handleClick={() => dispatch({type: cases.image13})}
        />
        ,
        <Pannellum.Hotspot
          type="custom"
          pitch={-21.07}
          yaw={175.62}
          handleClick={() => dispatch({type: cases.image9})}
        />
    ]
  },
  {
    img: image13,
    hotspots: [
      <Pannellum.Hotspot
          type="custom"
          pitch={-23.10}
          yaw={-130.55}
          handleClick={() => dispatch({type: cases.image12})}
        />
        ,
        <Pannellum.Hotspot
          type="custom"
          pitch={-8.30}
          yaw={54.96}
          handleClick={() => dispatch({type: cases.image14})}
        />
    ]
  },
  {
    img: image14,
    hotspots: [
      <Pannellum.Hotspot
          type="custom"
          pitch={-5.66}
          yaw={-12.90}
          handleClick={() => dispatch({type: cases.image13})}
        />
    ]
  }
]

    return(
    <>  
        <div id="virtual-tour-component" className="virtual-tour-component">
          <h2 className="virtual-tour-component-title">Recorrido 360</h2>
          <Pannellum
          ref={pannellumRef}
          height="100%"
          width="100%"
          background-size="cover"
          image={hotspotsByImage[state].img}
          autoLoad
          mouseZoom={false}
          hfov={130}
          maxHfov={130}
          type="tour"
          compass
          // hotspotDebug
          >
            { hotspotsByImage[state].hotspots }
          </Pannellum>
      </div>
    </>
    )
}
export default VirtualTourComponent;