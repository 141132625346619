import './FootComponent.css'

const FootComponent = () => {

    return(
        <>
        <div className='foot-component'>
            <div className='blur'>
                <div className='background-img-foot'></div>
            </div>
            <div className='footer-container'>
                <iframe title="google-maps-api" className='maps-embed' width="500" height="500" loading="lazy" allowFullScreen src='https://www.google.com/maps/embed/v1/place?q=C.%2016%201406%2C%20Gonnet%2C%20Provincia%20de%20Buenos%20Aires%2C%20Argentina&key=AIzaSyCBBtE-dZKWLvrO2Sy4ozYVwCMoEvVeumM'></iframe>
                <div className="footer-text-container">
                    <p className="footer-text">
                        Teléfono: +54 221 604-9887<br/>
                        Dirección:  C. 16 1406, Gonnet, Provincia de Buenos Aires, Argentina.<br/>
                    </p>
                </div>
            </div>
        </div>
        </>
    )
};

export default FootComponent;