import './NotFoundPageStyles.css';
import Button from 'react-bootstrap/Button';

const NotFoundPage = () => (
  <div>
      <div className="no-backgrond-div">
        <p className="parrafo-not-found text-uppercase">Error 404: Pagina no encontrada.</p><br />
        <Button className="home-button" variant="secondary" href="/inicio">Inicio</Button>
      </div>
  </div>
);

export default NotFoundPage;
