import './Description1Component.css'

const Description1Component = () => {
    return(
        <>
        <div id="nosotros" className="brown-description">
            <div className="brown-description-elements">
                <h2 className='brown-description-title'>En Buonavita cuidamos a quienes te cuidaron</h2>
                <p className='brown-description-parrafo'>
                    Nuestras instalaciones fueron pensadas y diseñadas con el objetivo de la atención y el bienestar del adulto mayor.
                    <br/>
                    Brindamos profesionalismo con empatía en el cuidado del adulto, adaptándonos a las necesidades de cada uno de nuestros residentes.
                    Contamos con un equipo profesional médico, enfermería, terapistas y acompañantes para priorizar la calidad de nuestra atención.
                    <br/>
                    Ofrecemos residencias permanentes o temporales con el mayor confort, con actividades recreativas y terapéuticas.
                </p>
                <ul className='brown-description-parrafo-2'>
                    <li> Acogemos estancias permanentes (hogar vivienda).</li>
                    <li> Estancias temporales: recuperaciones hospitalarias, post cirugías, viajes familiares, vacaciones.</li>
                    <li> Centro de día: Recuperación física adaptada a secuelas de enfermedades neurológicas, limitaciones propias de la edad, motricidad fina y readaptación al movimiento para lograr una mejor calidad de vida.</li>
                    <li> Equipo asistencial las 24hs.</li>
                    <li> Atencion medica con profesional especializado en clínica médica.</li>
                    <li> Recuperación motora / cognitiva. </li>
                    <li> Gimnasio adaptado.</li>
                    <li> Sala de masajes equipado con sillones Cuántico Caliver bt calor gravity</li>
                    <li> Terapia Ocupacional.</li>
                    <li> Salón de belleza, Barberia, manicura y podología. </li>
                    <li> Gastronomía gourmet con sabor casero.</li>
                </ul>
            </div>
        </div>
        </>
    )
}

export default Description1Component;