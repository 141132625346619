import React from 'react';
import Button from 'react-bootstrap/Button'
import backgroundHome from '../../assets/home-images/image4background.png';
import imageMan1 from '../../assets/home-images/image4man1.png';
import imageMan2 from '../../assets/home-images/image4man2.png';
import './HeadComponent.css';
import useNavbarScroll from 'src/hooks/useNavbarScroll';

const HeadComponent = () => {

  const {layer1Ref, layer2Ref, layer3Ref} = useNavbarScroll();

  return(
  <>
      <div id="inicio" className="head-component background">
          <div ref={layer1Ref} id="layer1" className="image-slider layer1" style={{backgroundImage: `url(${backgroundHome})`}}></div>
          <div className="head-component-elements">
              <div className="head-component-text">Cuidá bien<br/>
                  a quien cuidó<br/>
                  de vos
              </div>
              <Button className="contactanos-btn" variant="light" href="#contacto">Contáctanos</Button>
          </div>
          <div ref={layer2Ref} id="layer2" className="image-slider layer2" style={{backgroundImage: `url(${imageMan1})`}}></div>
          <div ref={layer3Ref} id="layer3" className="image-slider layer3" style={{backgroundImage: `url(${imageMan2})`}}></div>
      </div>
  </>
  )
};
export default HeadComponent;