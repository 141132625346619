import React from "react";
import { Carousel, CarouselItem } from 'react-bootstrap';
import image1 from '../../assets/Fotos/1 - Pasillos/1 - 4-min.jpg';
import image2 from '../../assets/Fotos/1 - Pasillos/1 - 2-min.jpg';
import image3 from '../../assets/Fotos/5 - Estar/5 - 1-min.jpg';
import proyectoBuonaVita from '../../assets/home-images/proyecto-buonavita1.mp4';
import './CarouselComponent.css'
const CarouselComponent = () => {
    
    return(
    <>
        <div id="imagenes" className="home-component-2 background">
            <div id="home-component-2-elements" className="home-component-2-elements">
                <Carousel pause={'hover'} keyboard interval={3500}>
                    <CarouselItem>
                        <video className="slide" autoPlay muted loop>
                            <source src={proyectoBuonaVita}></source>
                            Sorry, your browser doesn't support videos.
                        </video>
                    </CarouselItem>
                    <CarouselItem>
                        <img 
                        className="slide"
                        src={image1}
                        alt="Second slide" 
                        />
                        <Carousel.Caption className='caption'>
                        <h3> </h3>
                        <p className='caption-parrafo'> </p>
                        </Carousel.Caption>
                    </CarouselItem>
                    <CarouselItem>
                        <img 
                        className="slide"
                        src={image2}
                        alt="Third slide" 
                        />
                        <Carousel.Caption className='caption'>
                        <h3> </h3>
                        <p className='caption-parrafo'> </p>
                        </Carousel.Caption>
                    </CarouselItem>
                    <CarouselItem>
                        <img 
                        className="slide"
                        src={image3}
                        alt="Third slide" 
                        />
                        <Carousel.Caption className='caption'>
                        <h3> </h3>
                        <p className='caption-parrafo'> </p>
                        </Carousel.Caption>
                    </CarouselItem>
                </Carousel>
            </div>
        </div>
    </>
    )
};

export default CarouselComponent;