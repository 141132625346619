import './VirtualTourInvitationComponent.css'

const VirtualTourInvitationComponent = () => {
    return(
    <>
    <div id="virtual-tour-invitation-component" className="virtual-tour-invitation-component">
          <div className="blur">
            <div className="background-img-vr"></div>
          </div>
          <div className="virtual-tour-text-container">
            <h2 className='virtual-tour-title'>Paseo virtual</h2>
            <p className='virtual-tour-parrafo'>En el siguiente cuadro interactivo podrás ver en detalle las comodidades que tenemos para ofrecer. Al mover el cursor, la cámara también lo hará.</p>
          </div>
        </div>
    </>
    )
}

export default VirtualTourInvitationComponent;