import './HexComponent.css'

const HexComponent = (props: any) => {
    const onClick = props.onClick

    return (
        <>
            <div onClick={onClick} className="hex"><div className="hex-background">{props.children}</div></div>
        </>
    )
}

export default HexComponent;